import React, { Component } from "react";
import Navbar from "../../components/navbar";
import * as projectStyles from "../../styles/css/project.module.css";

class Qwaris extends Component {
    render(){
        return (
            <React.Fragment>
                <Navbar color="white"/>
                <div>
                    <div className={projectStyles.back}>
                        <div id="qwarisbanner"></div>
                    </div>
                    <div className={projectStyles.banner}>
                        <div>
                            <span style={{fontStyle:"italic"}}>Création d'une application mobile</span>
                            <h1 style={{fontSize: "2em", textTransform:"uppercase"}}>QWARIS (Prochainement)</h1>
                            <span style={{color:"#DCDCDC", textTransform:"uppercase", fontSize: "0.8em"}}>Type de projet</span>
                            <span style={{fontSize: "0.8em"}}>Application mobile</span>
                        </div>
                    </div>
                    {/*<div style={{background:"white"}}>
                    <div className={projectStyles.wrapper}>
                        <div className={projectStyles.container}>
                            <div>
                                <ul className={projectStyles.details}>
                                    <li>Client</li>
                                    <li>En cours</li>
                                </ul>
                                <ul className={projectStyles.details}>
                                    <li>Projet</li>
                                    <li>Création d'une application mobile sur-mesure</li>
                                </ul>
                                <ul className={projectStyles.details}>
                                    <li>Date</li>
                                    <li>En cours</li>
                                </ul>
                            </div>
                            <div>
                                <p>
                                    Ce projet actuellement en cours a pour but de créer une application mobile permettant de simplifier
                                    la gestion des interventions de maintenance de notre client.
                                </p>
                                <p>
                                    Ce projet est réalisé à l'aide des framework Flutter et Django.
                                </p>
                            </div>
                        </div>
                        <img src={ads_screen} className={projectStyles.example} alt={"Page d'accueil du site ads53.com"}></img>
                        <img src={ads_services} className={projectStyles.example} alt={"Page services du ads53.com"}></img>
                        <div className={projectStyles.mobile}>
                            <img src={ads_product} className={projectStyles.product} alt={"Page taille ordinateur d'un produit du site ads53.com"}></img>
                            <img src={ads_mobile} className={projectStyles.productMobile} alt={"Page taille mobile d'un produit du site ads53.com"}></img>
                        </div>
                    </div>
        </div>*/}
                </div>
            </React.Fragment>  
        )
    }
}

export default Qwaris;